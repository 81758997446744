<template>
  <DocumentItem :value="item" v-if="item" />
</template>
<script>
import DocumentItem from '@/components/DocumentItem.vue';

export default {
  components: { DocumentItem },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      item: {},
    };
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.item = await this.apiGet({
        resource: 'document/document',
        id: this.id,
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

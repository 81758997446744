<template>
  <Page title="Q-Arbeit" color="success">
    <template slot="extension">
      <v-tabs
        slider-color="white"
        background-color="success darken-1"
        v-if="$vuetify.breakpoint.xs"
        center-active
      >
        <v-tab :to="{ name: 'QualityActivities' }">Arbeiten </v-tab>
        <v-tab :to="{ name: 'QualityFeedbacks' }">Feedbacks</v-tab>
        <v-tab :to="{ name: 'QualitySchoolclasses' }">Klassen</v-tab>
        <v-tab :to="{ name: 'QualityEdit' }">Meine</v-tab>
        <v-tab :to="{ name: 'QualityDocuments' }">Dokumente </v-tab>
      </v-tabs>
      <v-tabs
        slider-color="white"
        background-color="success darken-1"
        v-else
        align-with-title
      >
        <v-tab :to="{ name: 'QualityActivities' }">Q-Arbeiten </v-tab>
        <v-tab :to="{ name: 'QualityFeedbacks' }">Feedbacks</v-tab>
        <v-tab :to="{ name: 'QualitySchoolclasses' }">Klassen</v-tab>
        <v-tab :to="{ name: 'QualityEdit' }">Meine</v-tab>
        <v-tab :to="{ name: 'QualityDocuments' }">Q-Dokumente </v-tab>
      </v-tabs>
    </template>
    <template slot="before">
      <v-toolbar flat class="mt-2">
        <v-col>
          <DocumentItemLoader :id="90001" />
        </v-col>
        <v-col v-if="isAdmin">
          <LookupValueInput
            v-model="period"
            :items="periods"
            label="Q-Periode"
            hide-details
            single-line
            class="mr-4"
          /> </v-col
        ><v-col v-if="isAdmin" cols="auto">
          <ReportButton
            icon
            resource="report/qualityactivities"
            :parameters="{ period: period }"
          />
        </v-col>
        <v-col>
          <v-text-field
            hide-details
            single-line
            :label="
              $vuetify.breakpoint.xs ? 'Suche' : 'Name, Küerzel (oder Klasse)'
            "
            v-model="search"
            append-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </v-col>
      </v-toolbar>
    </template>
    <router-view :search="search" :period="period"></router-view>
  </Page>
</template>

<script>
import DocumentItemLoader from "@/components/DocumentItemLoader.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import ReportButton from "@/components/ReportButton.vue";

export default {
  components: { DocumentItemLoader, LookupValueInput, ReportButton },
  data() {
    return {
      loading: false,
      period: null,
      periods: [],
      search: "",
    };
  },
  computed: {
    isAdmin() {
      return this.$_hasRole(["qualityAdmin", "schoolAdmin", "sysAdmin"]);
    },
  },
  async created() {
    if (this.isAdmin) {
      this.periods = await this.apiList({ resource: "quality/period" });
      this.period = await this.apiList({
        resource: "quality/period",
        query: "current",
      });
    }
  },
};
</script>
